<template>
  <layout :title="$route.name" :toolbar_off="true">
    <v-flex xs12 class="pa-3 pb-0">
      <div class="expande-horizontal centraliza">
        <div class="expande-horizontal column title-item">
          <v-avatar
            @click="$router.go(-1)"
            size="36"
            style="cursor: pointer;"
            color="#e5e5e5"
            icon
          >
            <v-icon color="#000" size="26">mdi-arrow-left</v-icon>
          </v-avatar>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center; height: 40px;"
        >
          <v-icon color="#666" size="38" style="padding-bottom: 3px;"
            >mdi-cash-fast</v-icon
          >
          <span style="font-size: 19pt; text-wrap: nowrap;" class="fonte-bold">
            Saque
          </span>
        </div>
      </div>
    </v-flex>
    <v-window touchless v-model="step">
      <v-window-item value="1">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                Verificação de Conta Digital SCN Revolution
              </span>
            </v-flex>
            <!-- <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                v-mask="['###.###.###-##']"
                prepend-inner-icon="mdi-account-circle-outline"
                large
                v-model="cpf"
                autofocus
              ></v-text-field>
            </v-flex> -->
            <v-flex xs12>
              <v-list class="px-0" color="transparent" v-if="error_message" nav>
                <v-list-item
                  style="background: #FFEBEE; border-radius: 12px; margin-bottom: 6px;"
                >
                  <v-list-item-content>
                    <v-list-item-title class="red--text fonte-bold">
                      Atenção
                    </v-list-item-title>
                    <v-list-item-subtitle class="red--text fonte">
                      {{ error_message }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="red">mdi-alert-outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>
            <!-- <v-flex v-if="!pinTargetAccount" class="mb-4" xs12>
              <v-text-field
                filled
                color="black"
                label="Código de canal"
                rounded
                prepend-inner-icon="mdi-bank-outline"
                large
                type="number"
                hide-details
                v-model="channel_code"
                autofocus
              ></v-text-field>
            </v-flex> -->
            <v-flex v-if="!pinTargetAccount" xs12>
              <v-text-field
                filled
                color="black"
                label="Código de cliente"
                rounded
                prepend-inner-icon="mdi-account-circle-outline"
                large
                type="number"
                v-model="user_code"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-list
                class="px-0"
                color="transparent"
                v-if="pinTargetAccount"
                nav
              >
                <v-list-item
                  style="background: #E3F2FD; border-radius: 12px; margin-bottom: 6px;"
                >
                  <v-list-item-content>
                    <v-list-item-title class="blue--text fonte-bold">
                      {{ pinTargetAccount.Nome }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="blue--text fonte">
                      {{ pinTargetAccount.Cpf }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon color="blue">mdi-check</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-flex>

            <v-flex xs12>
              <div class="expande-horizontal centraliza">
                <v-btn
                  v-if="!pinTargetAccount"
                  @click="getDestUser"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Buscar Conta</v-btn
                >
                <!-- <v-btn
                  v-else
                  @click="createAccountBank"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Avançar</v-btn
                > -->
                <v-btn
                  v-else
                  @click="createAccountBank"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Avançar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>

      <v-window-item value="2">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex xs12>
              <v-list class="fonte" color="transparent">
                <!-- <v-list-item v-if="false" class="px-0">
                  <v-avatar color="#333" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-account-circle-outline</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Destino
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
                <v-list-item class="px-0">
                  <v-avatar color="#333" rounded class="mr-2">
                    <v-icon color="#f2f2f2">mdi-cash</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $helper.formataSaldo(balance.balance || 0) }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Saldo disponível
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
            <v-flex class="px-1" xs12>
              <span class="fonte-bold" style="font-size: 24pt;">
                Valor do saque
              </span>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                filled
                color="black"
                rounded
                prefix="R$"
                v-mask="[
                  '#,##',
                  '##,##',
                  '###,##',
                  '####,##',
                  '#####,##',
                  '######,##'
                ]"
                large
                v-model="value"
                autofocus
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="expande-horizontal column centraliza">
                <v-btn
                  @click="sendRequestWithdraw"
                  :loading="loading"
                  x-large
                  color="#333"
                  dark
                  rounded
                  >Solicitar</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="3">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span
                class="fonte-bold mb-6 expande-horizontal centraliza"
                style="font-size: 24pt;"
              >
                <v-avatar size="120" color="green">
                  <v-icon color="white" size="100">mdi-check</v-icon>
                </v-avatar>
              </span>
            </v-flex>
            <v-flex class="px-1" xs12>
              <div class="expande-horizontal centraliza column">
                <span class="fonte-bold" style="font-size: 24pt;">
                  Saque solicitado
                </span>
                <span class="fonte grey--text mb-2" style="font-size: 12pt;">
                  Seu saque está sendo processado!
                </span>
              </div>
            </v-flex>
            <v-flex xs12 class="mt-3">
              <div class="expande-horizontal centraliza">
                <v-btn
                  @click="$router.push('/dashboard')"
                  color="black"
                  dark
                  large
                  rounded
                  class="fonte-bold"
                  >Ir para o início</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>
      <v-window-item value="4">
        <v-flex xs12 class="px-3" md6>
          <div class="pt-6 mb-6 centraliza expande-horizontal wrap">
            <v-flex class="px-1" xs12>
              <span
                class="fonte-bold mb-6 expande-horizontal centraliza"
                style="font-size: 24pt;"
              >
                <v-avatar size="120" color="orange">
                  <v-icon color="white" size="60">mdi-alert-outline</v-icon>
                </v-avatar>
              </span>
            </v-flex>
            <v-flex class="px-1" xs12>
              <div class="expande-horizontal centraliza column">
                <span class="fonte-bold" style="font-size: 24pt;">
                  Opa!
                </span>
                <span class="fonte grey--text mb-2" style="font-size: 12pt;">
                  Verifique a internet e tente novamente!
                </span>
              </div>
            </v-flex>
            <v-flex xs12 class="mt-3">
              <div class="expande-horizontal centraliza">
                <v-btn
                  @click="step = '2'"
                  color="black"
                  dark
                  large
                  rounded
                  class="fonte-bold"
                  >Tentar novamente</v-btn
                >
              </div>
            </v-flex>
            <v-flex xs12 class="mt-3">
              <div class="expande-horizontal centraliza">
                <v-btn
                  @click="$router.push('/dashboard')"
                  color="black"
                  dark
                  text
                  class="fonte-bold"
                  >Ir para o início</v-btn
                >
              </div>
            </v-flex>
          </div>
        </v-flex>
      </v-window-item>
    </v-window>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["transaction_type"],
  data() {
    return {
      channel_code: "",
      user_code: "",
      target_account: {},
      step: "1",
      value: "",
      loading: false,
      error_message: "",
      pinTargetAccount: false
    };
  },
  methods: {
    ...mapActions([
      "getDestinationUser",
      "requestPayment",
      "list_accounts",
      "create_account",
      "show_pinbank_account",
      "listBalanceUnilevel"
    ]),
    getDestUser() {
      this.error_message = "";
      this.loading = true;
      this.show_pinbank_account({
        channel_code: this.channel_code,
        user_code: this.user_code
      })
        .then(res => {
          this.loading = false;
          this.pinTargetAccount = res ? JSON.parse(res).Data : {};
        })
        .catch(err => {
          this.loading = false;
          this.error_message = err.response.data.message;
        });
    },
    createAccountBank() {
      this.create_account({
        target: this.pinTargetAccount,
        client_code: this.user_code,
        channel_code: this.channel_code
      })
        .then(res => {
          this.target_account = res;
          this.step = "2";
        })
        .catch(err => {
          this.error_message = err.response.data.message;
        });
    },
    sendRequestWithdraw() {
      if (parseFloat(this.value) < 1) {
        alert("O valor deve ser 1 real ou maior");
        return;
      }
      this.requestPayment({
        tipo_saque: 'unilevel',
        value: this.value,
        pinTargetAccount: this.pinTargetAccount,
        transaction_type: this.transaction_type
      })
        .then(() => {
          this.step = "3";
        })
        .catch(err => {
          this.step = "4";
        });
    }
  },
  computed: {
    ...mapGetters(["getLoggedUser", "getBalanceUnilevel"]),
    balance() {
      return this.getBalanceUnilevel;
    }
  },
  created() {
    // setInterval(() => {
    //   if (this.step == '4') {
    //     this.step = "1";
    //   } else {
    //     this.step = `${parseInt(this.step) + 1}`;
    //   }
    // }, 4000)
    this.listBalanceUnilevel();
    this.list_accounts()
      .then(res => {
        if (Array.isArray(res) && res.length == 0) {
          this.error_message =
            "Digite abaixo o número da sua conta digital Revolution";
        } else {
          this.target_account = res[0];
          this.step = "2";
        }
      })
      .catch(err => {
        this.error_message = err.response.data.message;
      });
  }
};
</script>
